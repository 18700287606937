import type { Image, ImageFormatKeys } from "~/modules/shared/ts/Image";

export const useImageUrl = (
  data: Image,
  key: ImageFormatKeys,
  fallback?: ImageFormatKeys,
) => {
  return (
    data.data?.attributes.formats?.[key]?.url ??
    (fallback && data.data?.attributes.formats?.[fallback]?.url) ??
    data.data?.attributes.url
  );
};
